import { InvalidErrorType } from "#lib/errors";
import { DescriptionList, DescriptionSection } from "#components/lists";

interface IProps {
  routePathPattern: string;
  error: InvalidErrorType;
}

export function InvalidErrorView({ routePathPattern, error }: IProps) {
  const { name, message, payload } = error;
  return (
    <DescriptionList>
      <DescriptionSection
        dKey="Page"
        dValue={routePathPattern}
        isValuePreformatted
      />

      <DescriptionSection
        dKey="Name"
        dValue={name}
        isHorizontal
        isValuePreformatted
      />

      <DescriptionSection dKey="Message" dValue={message} isValuePreformatted />

      <DescriptionSection
        dKey="Payload"
        dValue={String(payload)}
        isValuePreformatted
      />
    </DescriptionList>
  );
}
