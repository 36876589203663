import { APIError } from "#lib/api";
import { HTTP_STATUS } from "#lib/http";
import { DescriptionList, DescriptionSection } from "#components/lists";

interface IProps {
  routePathPattern: string;
  error: APIError;
}

export function APIErrorView({ routePathPattern, error }: IProps) {
  const { name, message, request, response } = error;
  const { status } = response;
  const errorOrigin =
    status >= HTTP_STATUS.INTERNAL_SERVER_ERROR
      ? status === HTTP_STATUS.SERVICE_UNAVAILABLE
        ? "Server (no need to report since owner should be aware of it)"
        : "Server"
      : "Client (this browser and/or this site's scripts and/or client's network)";

  return (
    <DescriptionList>
      <DescriptionSection
        dKey="Originated from"
        dValue={errorOrigin}
        isHorizontal
        isValuePreformatted
      />

      <DescriptionSection
        dKey="Page"
        dValue={routePathPattern}
        isValuePreformatted
      />

      <DescriptionSection
        dKey="Name"
        dValue={name}
        isHorizontal
        isValuePreformatted
      />

      <DescriptionSection dKey="Message" dValue={message} isValuePreformatted />

      <DescriptionSection
        dKey="Request"
        dValue={
          <DescriptionList>
            <DescriptionSection
              dKey="URL"
              dValue={request.url}
              isValuePreformatted
            />

            <DescriptionSection
              dKey="Method"
              dValue={request.method}
              isHorizontal
              isValuePreformatted
            />
          </DescriptionList>
        }
      />

      <DescriptionSection
        dKey="Response"
        dValue={
          <DescriptionList>
            {request.url !== response.url && (
              <DescriptionSection
                dKey="URL"
                dValue={response.url}
                isValuePreformatted
              />
            )}

            <DescriptionSection
              dKey="Status code"
              dValue={response.status}
              isHorizontal
              isValuePreformatted
            />

            {response.statusText && (
              <DescriptionSection
                dKey="Status text"
                dValue={response.statusText}
                isValuePreformatted
              />
            )}

            <DescriptionSection
              dKey="Type"
              dValue={response.type}
              isHorizontal
              isValuePreformatted
            />
          </DescriptionList>
        }
      />
    </DescriptionList>
  );
}
