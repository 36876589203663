import { useEffect, useRef } from "react";
import { VIDEO_AD } from "#env/env-vars";
import { IPostVideo } from "#entities/posts";

interface IPostVideoProps {
  video: IPostVideo;
}

export function PostVideo({ video }: IPostVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }

    let fluidPlayer: FluidPlayerInstance | undefined = undefined;

    console.time(`${video.path}-create`);
    console.time(`${video.path}-vast`);
    import("fluid-player")
      .then(({ default: fluidPlayerModule }) => {
        fluidPlayer = fluidPlayerModule(videoElement, {
          layoutControls: {
            fillToContainer: false,
            preload: "none",
          },
          vastOptions: {
            adList: JSON.parse(atob(VIDEO_AD)),
            adTextPosition: "top left",
            maxAllowedVastTagRedirects: 2,
            vastAdvanced: {
              vastLoadedCallback: () => {
                console.timeLog(`${video.path}-vast`, "Loaded VAST video.");
              },
              noVastVideoCallback: () => {
                console.timeLog(`${video.path}-vast`, "No VAST video loaded.");
              },
              vastVideoSkippedCallback: () => {
                console.timeLog(`${video.path}-vast`, "Skipped VAST video.");
              },
              vastVideoEndedCallback: () => {
                console.timeLog(`${video.path}-vast`, "VAST video ended.");
              },
            },
          },
        } satisfies Partial<FluidPlayerOptions>);
      })
      .then(() => {
        console.timeEnd(`${video.path}-create`);
      })
      .catch((error) => {
        const message = `Failed to initialize Fluid Player for video "${video.path}".`;
        console.error(new Error(message, { cause: error }));
      });

    return () => {
      if (fluidPlayer) {
        console.time(`${video.path}-destroy`);
        const onEndedEvent = new Event("ended")
        videoRef.current?.dispatchEvent(onEndedEvent)
        fluidPlayer.destroy();
        console.timeEnd(`${video.path}-destroy`);
        console.timeEnd(`${video.path}-vast`);
      }
    };
  }, [video.path]);

  return (
    <li>
      <summary>{video.name}</summary>

      {!video.caption ? undefined : <summary>{video.caption}</summary>}

      <video
        ref={videoRef}
        id={`kemono-player${video.index}`}
        className="post__video"
        controls
        playsInline
        preload="none"
      >
        <source
          src={`${video.server ?? ""}/data${video.path}`}
          type={`video/${video.extension.slice(1)}`}
        />
      </video>
    </li>
  );
}
