import { FetchError } from "./errors";

export async function customFetch(...args: Parameters<typeof fetch>) {
  const request = new Request(...args);


  try {
    const response = await fetch(request);

    return response;
  } catch (error) {

    throw new FetchError("Failed to fetch due to client settings.", {
      cause: error,
      request,
    });
  }
}
