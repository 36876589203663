import { InvalidErrorType } from "#lib/errors";

interface IFetchError extends Error {
  request: Request;
}

interface IFetchErrorOptions extends ErrorOptions {
  request: Request;
}

/**
 * `fetch()` by itself doesn't throw, it only does so due to some client settings
 * i.e. improperly formed headers/body/url by the client code
 * or browser extensions/adblockers terminating it early.
 *
 * Since it throws before doing any actual request, the response object is not available to it.
 */
export class FetchError extends Error implements IFetchError {
  request: Request;

  constructor(message: string, options: IFetchErrorOptions) {
    super(message);

    this.request = options.request;
  }
}

export function isFetchError(input: unknown): input is FetchError {
  return input instanceof FetchError;
}

export function ensureFetchError(input: unknown): asserts input is FetchError {
  if (!isFetchError(input)) {
    throw new InvalidErrorType(input);
  }
}
